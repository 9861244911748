<div class="panel">

	<header>
		<h1>Instant Rebate Claim</h1>


		<div class="stepper">
			<div class="dot" [ngClass]="{seen: step >= 1}"></div>
			<div class="dot" [ngClass]="{seen: step >= 2}"></div>
			<div class="dot" [ngClass]="{seen: step >= 3}"></div>
		</div>
	</header>


	<main *ngIf="step == 1">
		<section class="program-info">
			<div class="dates">
				<div class="title">Program Date:</div>
				<div class="value">{{rebate.rebateProgram.startDate | date: 'M/d/yyyy'}} - {{rebate.rebateProgram.endDate | date: 'M/d/yyyy'}}</div>
			</div>

			<div class="vendor">
				<div class="title">Program Name:</div>
				<div class="value">{{rebate.rebateProgram.vendorName}}</div>
			</div>

			<div class="flex flex-column md:flex-row md:align-items-start md:justify-content-between mb-3">
				<div class="title">Search:</div>
				<div class="inline-flex align-items-center">
					<span class="p-input-icon-left flex-auto">
						<i class="pi pi-search"></i>
						<input pInputText type="text" (input)="onGlobalFilter(dt, $event)" placeholder="Search" class="w-full" style="border-radius: 2rem" />
					</span>
				</div>
			</div>
			<div class="download-upload">

				<!--<div class="btn-holder"><div class="title">&nbsp;</div>  <div class="button" (click)="dt.exportCSV()">Download</div></div>-->

				<div class="btn-holder"><div class="title">&nbsp;</div>  <div class="button" (click)="exportExcel()">Download</div></div>

				<div class="btn-holder">
					<div class="title">&nbsp;</div>
					<div class="upload-button">
						<!--<input type="file" [accept]="'.csv'" (change)="uploadQuantities($event)" #uploadOrderFileField>-->
						<input type="file" [accept]="'.xlsx, .xls, .csv'" (change)="uploadFile($event)" #uploadOrderFileField>

						<div class="button">Upload</div>
					</div>
				</div>
			</div>
		</section>
		<section class="models">
			<p-table #dt [value]="item" [columns]="columns" [paginator]="true"
							 [rows]="500" responsiveLayout="scroll"
							 [globalFilterFields]="['rebateType','proCode','catalogNumber','brand','modelName','reimbursement','quantity']">
				<ng-template pTemplate="header">
					<tr class="row header">
						<th pSortableColumn="rebateType" class="RebateType">Type<p-sortIcon field="rebateType"></p-sortIcon></th>
						<th pSortableColumn="proCode" class="model">Model<p-sortIcon field="proCode"></p-sortIcon></th>
						<th pSortableColumn="catalogNumber" class="catalogNumber">Catalog #<p-sortIcon field="catalogNumber"></p-sortIcon></th>
						<th pSortableColumn="brand" class="catalogNumber">Brand<p-sortIcon field="brand"></p-sortIcon></th>
						<th pSortableColumn="modelName" class="model-name">Model Name	<p-sortIcon field="modelName"></p-sortIcon></th>
						<th pSortableColumn="reimbursement" class="reimbursement">Reimbursement<p-sortIcon field="reimbursement"></p-sortIcon></th>
						<th pSortableColumn="quantity" class="quantity">	Quantity<p-sortIcon field="quantity">								</p-sortIcon></th>
						<th pSortableColumn="total" class="total">Total</th>
						<th pSortableColumn="status" class="status">Status</th>
						<th pSortableColumn="available" class="available">Available</th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-item>
					<tr class="row">
						<td><div class="RebateType">{{ item.rebateType }}</div></td>
						<td><div class="model">{{ item.proCode }}</div></td>
						<td><div class="model">{{ item.catalogNumber }}</div></td>
						<td><div class="model">{{ item.brand }}</div></td>
						<td><div class="model-name">{{ item.modelName }}</div></td>
						<td><div class="reimbursement">{{ item.reimbursement | currency }}</div></td>
						<td><div class="quantity"> <input [(ngModel)]="item.quantity" type="number" min="0" (change)="validateRebate(item)" (keyup)="validateRebate(item)"></div></td>
						<td><div class="total">{{item.reimbursement * item.quantity | currency}}</div></td>
						<td><div class="status {{item.status}}"><span>{{item.status}}</span></div></td>
						<td><div class="available">{{item.available}}</div></td>
					</tr>
				</ng-template>
			</p-table>
		</section>
	</main>

	<main *ngIf="step == 2">
		<section class="instructions">
			<h2>Instructions</h2>


			<p-messages [(value)]="errorMessage"
									[enableService]="false">
			</p-messages>




			<div *ngFor="let Direction of programDirections ; first as isFirst">
				<p>
					<B>		{{Direction.rebateTypeName}}</B>
					<br /> {{Direction.rebateDirections}}


					<!-- Social Media is type 6 -->
					<div *ngIf="Direction.rebateTypeID===6">
						<div class="upload-button">
							<div class="button">
								<mat-icon>add</mat-icon>
								<span>Upload/Add File</span>
							</div>
							<input type="file" (change)="saveFileAdditional($event)">
						</div>

						<section class="files">
							<ul>
								<li *ngFor="let files of rebate.rebateFilesSecondary; let i = index">
									<div class="number">
										{{
													i + 1
										}}
									</div>
									<div class="file-name">{{files.fileName}}</div>
									<div class="file-size">{{files.fileSizeWithUnits}}</div>
									<div class="delete" (click)="deleteFileFileAdditional(i)"><mat-icon>clear</mat-icon></div>
								</li>
							</ul>
						</section>

					</div>

					<!-- This is exclusive for just a selection of Social Media.-->
					<div *ngIf="Direction.rebateTypeID===6 && countPrograms === 1">
						<p>You must attach an electronic copy of your POS report or sales receipts to process claim. Only PDF, Word, Excel and Text files accepted. There is a maximum of 5 attachement allowed. Please make sure tolimit the quantity of pages submitted as this causes delay in processing. </p>
						<div class="upload-button">
							<div class="button">
								<mat-icon>add</mat-icon>
								<span>Upload/Add File</span>
							</div>
							<input type="file" (change)="saveFile($event)">
						</div>


						<section class="files">
							<ul>
								<li *ngFor="let file of rebate.rebateFiles; let i = index">
									<div class="number">
										{{
									i + 1
										}}
									</div>
									<div class="file-name">{{file.fileName}}</div>
									<div class="file-size">{{file.fileSizeWithUnits}}</div>
									<div class="delete" (click)="deleteFile(i)"><mat-icon>clear</mat-icon></div>
								</li>
							</ul>
						</section>
					</div>

					<!-- If this is not Social Media and it's the first'.-->
					<div *ngIf="Direction.rebateTypeID!=6 && isFirst">
						<div class="upload-button">
							<div class="button">
								<mat-icon>add</mat-icon>
								<span>Upload/Add File</span>
							</div>
							<input type="file" (change)="saveFile($event)">
						</div>

						<section class="files">
							<ul>
								<li *ngFor="let file of rebate.rebateFiles; let i = index">
									<div class="number">
										{{
											i + 1
										}}
									</div>
									<div class="file-name">{{file.fileName}}</div>
									<div class="file-size">{{file.fileSizeWithUnits}}</div>
									<div class="delete" (click)="deleteFile(i)"><mat-icon>clear</mat-icon></div>
								</li>
							</ul>
						</section>

					</div>

					<BR>
					<BR>





			</div>


			<!--<p>
		You must attach an electronic copy of your POS report or sales receipts to process claim. Only PDF, Word,
		Excel and
		Text files accepted. There is
		a maximum of 5 attachement allowed. Please make sure tolimit the quantity of pages
		submitted as this causes delay in processing.
		<br />
		<br />
		Some rebate programs may require additional proof of performance, such as a screenshot of a social media post. When applicable please upload a PDF, jpeg, or png file to ensure the timely processing of your entire reimbursement.
	</p>-->
			<!--<div class="upload-button">
		<div class="button">
			<mat-icon>add</mat-icon>
			<span>Upload/Add File</span>
		</div>
		<input type="file" (change)="saveFile($event)">
	</div>-->
			<!--<div class="upload-button" *ngIf="RequiresAdditionalUpload">
		<div class="button">
			<mat-icon>add</mat-icon>
			<span>Upload/Add File</span>
		</div>
		<input type="file" (change)="saveFileAdditional($event)">
	</div>-->

		</section>

		<!--<section class="files">

			<ul>
				<li *ngFor="let files of rebate.rebateFilesSecondary; let i = index">
					<div class="number">
						{{
							i + 1
						}}
					</div>
					<div class="file-name">{{files.fileName}}</div>
					<div class="file-size">{{files.fileSizeWithUnits}}</div>
					<div class="delete" (click)="deleteFileFileAdditional(i)"><mat-icon>clear</mat-icon></div>
				</li>
			</ul>

		</section>-->


	</main>
	<main *ngIf="step == 3">
		<section class="form" [formGroup]="rebateForm">
			<div class="field">
				<label>First Name</label>
				<input type="text" pInputText [formControlName]="'fname'">
			</div>
			<div class="field">
				<label>Last Name</label>
				<input type="text" pInputText [formControlName]="'lname'">
			</div>
			<div class="field">
				<label>Email</label>
				<input type="text" pInputText [formControlName]="'email'">
			</div>
			<div class="field">
				<label>Phone</label>
				<input type="text" pInputText [formControlName]="'phone'">
			</div>
			<div class="field">
				<label>Debit Memo</label>
				<input type="text" pInputText [formControlName]="'memo'">
				<div class="error" *ngIf="rebateForm.get('memo').touched && rebateForm.get('memo').hasError('minlength')">Please enter at least 5 characters</div>
			</div>
		</section>

	</main>
	<footer>
		<div class="back">
			<div class="button" (click)="back()" *ngIf="step > 1">Back</div>
		</div>
		<div class="next" *ngIf="step < 3">
			<div class="button" (click)="next()">Next</div>
		</div>
		<div class="submit" *ngIf="step == 3">
			<div class="button" (click)="submit()">Submit My Claim</div>
		</div>
	</footer>
</div>
